<template>
  <div v-if="platformPermissionsLoaded && checkPermission('connect.topics.topicinsights')">
    <CRow>
      <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
        <div class="d-flex">
          <h1 class="dashboard_page_title flex-grow-1">{{$t('insights.Topics_insights')}}</h1>
          <div v-if="showDownloadButtons" class="insights_download_buttons text-right">
            <div class="mt-1 mt-xl-0 d-inline-block align-top mr-2">
              <CButton class="link m-0" color="primary" @click="makeScreenshot()">
                <i class="fas fa-image mr-1"/><span>{{ $t('insights.Save_as_image') }}</span>
              </CButton>                
            </div>
            <div class="mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="link m-0" color="primary" @click="exportData();">
                <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('insights.Save_as_json') }}</span>              
              </CButton>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>

    <CRow>
      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
        <div class="datepickers">
          <b-datepicker v-model="dateFrom"
                        icon="calendar-day"
                        icon-pack="fas"
                        :first-day-of-week="1"
                        :show-week-number="true"
                        :max-date="new Date()"
                        :years-range="[-3, 10]"
                        :placeholder="$t('common.click_to_select')"
                        @input="getTopicsData()"
                        class="d-inline-block align-middle mr-2">
            <CButton color="primary" @click="dateFrom = new Date(); getTopicsData();"><i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}</CButton>
          </b-datepicker>
          <b-datepicker v-model="dateTo"
                        icon="calendar-day"
                        icon-pack="fas"
                        :first-day-of-week="1"
                        :show-week-number="true"
                        :max-date="new Date()"
                        :years-range="[-3, 10]"
                        :placeholder="$t('common.click_to_select')"
                        @input="getTopicsData()"
                        class="d-inline-block align-middle">
            <CButton color="primary" @click="dateTo = new Date(); getTopicsData();"><i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}</CButton>
          </b-datepicker>
        </div>       
      </CCol>
    </CRow>

    <CRow ref="screenshotContent" class="insights" v-bind:class="{'printing' : printingInsights}">        
      <CCol cols="4" xl="4" lg="4" md="4" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.reach')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="text-center">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Gauge v-else :gaugeChartSeries="gaugeChartSeries" :companyPrimaryColor="companyPrimaryColor"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="4" xl="4" lg="4" md="4" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Average_posts_viewed')}}
              </CCol>       
            </CRow>
          </CCardHeader>
          <CCardBody class="text-center">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Grade v-else :grade="topicsInsightsData.reach.avg_post_viewed" :centered="true"/>
          </CCardBody>
        </CCard>
      </CCol>      

      <CCol cols="4" xl="4" lg="4" md="4" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">      
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.likes_per_type')}}
              </CCol>
            </CRow>      
          </CCardHeader>
          <CCardBody>
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <LikesGiven v-else :likesCountPerType="topicsInsightsData.sentiment.emotions" mode="grid"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
                {{$t('insights.Post_first_hit_trend')}}
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <BarChart v-else :barChartSeries="barChartSeries" xAxisType="category" yAxisType="integer" :companyPrimaryColor="companyPrimaryColor"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
                {{$t('insights.reach_in_topics')}}
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody v-bind:class="{'p-0' : !loadingData}">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <TopicsInsightsTable v-else :topics="topicsInsightsData.reach.topics"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
                {{$t('insights.reach_in_departments')}}
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody v-bind:class="{'p-0' : !loadingData}">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <TopicsInsightsDepTable v-else :departments="topicsInsightsData.reach.departments"/>
          </CCardBody>
        </CCard>
      </CCol>       
    </CRow>   
  </div>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

import Gauge from '@/components/insights/common/Gauge.vue';
import Grade from '@/components/insights/common/Grade.vue';
import BarChart from '@/components/insights/common/BarChart.vue';
import LikesGiven from '@/components/insights/common/LikesGiven.vue';

import TopicsInsightsTable from '@/components/insights/connect/TopicsInsightsTable.vue';
import TopicsInsightsDepTable from '@/components/insights/connect/TopicsInsightsDepTable.vue';

export default {
  name: 'Topics',
  components: {
    loadingSpinner,
    noPermission,
    Gauge,
    Grade,
    BarChart,
    LikesGiven,
    TopicsInsightsTable,
    TopicsInsightsDepTable
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      loadingData: false,
      topicsInsightsData: {
        reach: {
          avg_post_viewed: 0,
          percentage: 0,
          departments: [],
          topics: []
        },
        sentiment: { emotions: [] },
        topics_reach_graph: []
      },
      showDownloadButtons: false,
      printingInsights: false,
      dateFrom: new Date(),
      dateTo: new Date(),
      companyPrimaryColor: null,
      barChartSeries: [],
      gaugeChartSeries: []
    }
  },
  methods: {
    getTopicsData() {
      // Start the loader
      this.loadingData = true;
      // Clear the gaugeChartSeries array
      this.gaugeChartSeries = [];

      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd")      
      // Get the topics insights data
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/topics/insights/' + dateFromAPI + '/' + dateToAPI)      
      .then(res => {
        // Set the topicsInsightsData
        this.topicsInsightsData = res.data.data;
        // Update the barChartSeries
        this.barChartSeries = [{ name: "Reach percentage", data: this.topicsInsightsData.topics_reach_graph }];
        // Add the reach percentage to the gaugeChartSeries array
        this.gaugeChartSeries.push(this.topicsInsightsData.reach.percentage.toFixed(0));
        // Set the companyPrimaryColor value
        this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');
          
        // Set timeout for 2 seconds
        setTimeout(function(){
          // Close the loader
          this.loadingData = false;              
          // Update the showDownloadButtons value
          this.showDownloadButtons = true;
        }.bind(this), 2000)                         
      })
      .catch(err => {
        console.error(err); 
      });                  
    },
    exportData(outputData) {      
      this.$buefy.toast.open({ message: this.$t('common.Export_successful'), type: 'is-success', duration: 2000 });
      let exportFileName = 'topics-insights';
      
      if(!window.navigator.msSaveOrOpenBlob){
        // Blob navigator
        let url = null;
        // Set the output data and URL
        let outputData = JSON.stringify(this.topicsInsightsData);
        url = window.URL.createObjectURL(new Blob([outputData]));
        // Create a new 'a' element
        const link = document.createElement('a');
        // Set the generated URL as href of the new element
        link.href = url;
        // Set the download attribute
        link.setAttribute('download', exportFileName + '.json');
        // Append the link to the body
        document.body.appendChild(link);
        // Click the link
        link.click();
      } else {
        // Blob for Explorer 11
        let url = null;
        // Set the output data and URL        
        outputData = JSON.stringify(this.topicsInsightsData);
        url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), exportFileName + '.json');
      }
    },
    makeScreenshot() {
      window.scroll(0,0);
      // Update printingInsights value
      this.printingInsights = true;
      // Get the HTML to print
      const content = this.$refs.screenshotContent;
      // Set the html2canvas options
      const options = { type: "dataURL", useCORS: true };

      setTimeout(function() {
        // Print the screenshot
        this.printScreenshot(content, options);
        // Reset the printingInsights value
        this.printingInsights = false;
      }.bind(this), 500);
    },
    async printScreenshot(content, options) {
      // Create canvas for printing
      const printCanvas = await html2canvas(content, options);
      // Creata a link element to download the generated canvas
      const link = document.createElement("a");
      link.setAttribute("download", "topics_insights_" + new Date().toLocaleString() + ".png");
      link.setAttribute("href", printCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
      link.click();
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  },
  mounted: function() {
    // Set the default for dateFrom to today minus 1 year
    this.dateFrom.setFullYear(this.dateFrom.getFullYear() - 1);
    // Get the topics data
    this.getTopicsData();
    // Get the platform permissions
    this.getPlatformPermissions();
  }
}
</script>